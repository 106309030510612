<template>
	<div class="flexPage darkPage">
		<div class="flexHead">
			<navbar :params="pageInfo" />
		</div>
		<div class="flexContent">
			<!-- 表单 -->
			<div class="form">
				<template v-if="newSet">
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.account.newpwd') }}</div>
						<van-field type="password" v-model="form.newPassword" clearable
							:placeholder="$t('form.account.n2')"></van-field>
					</div>
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.account.repwd') }}</div>
						<van-field type="password" v-model="form.rePassword" clearable
							:placeholder="$t('form.account.r2')"></van-field>
					</div>
				</template>
				<template v-if="updateSet">
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.account.oldpwd') }}</div>
						<van-field type="password" v-model="form.oldPassword" clearable
							:placeholder="$t('form.account.o2')"></van-field>
					</div>
					<!-- <div class="cellGroup">
						<div class="tbTitle">{{ $t('form.assets.sms') }}</div>
						<van-field v-model="form.sms" :placeholder="$t('form.signup.m2')" clearable>
						</van-field>
						<button slot="button" class="btn-code" :disabled="sms.status == 1 || !hasMobile"
							@click="sendSms('mobile')">{{ smsBtn }}</button>
					</div> -->
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.signup.emailCode') }}</div>
						<van-field v-model="form.email" :placeholder="$t('form.signup.e2')" clearable>
						</van-field>
						<button slot="button" class="btn-code" :disabled="countingDown" @click="sendSms('email')">{{
							buttonText }}</button>
					</div>
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.account.newpwd') }}</div>
						<van-field type="password" v-model="form.newPassword" clearable
							:placeholder="$t('form.account.n2')"></van-field>
					</div>
					<div class="cellGroup">
						<div class="tbTitle">{{ $t('form.account.repwd') }}</div>
						<van-field type="password" v-model="form.rePassword" clearable
							:placeholder="$t('form.account.r2')"></van-field>
					</div>
				</template>
			</div>
		</div>

		<van-tabbar>
			<van-tabbar-item>
				<van-button v-if="newSet" style="width: 327px;" color="#3861FB" :loading="submiting" @click="handleSubmit()">{{
					$t('button.otc.sure') }}</van-button>
				<van-button v-if="updateSet" style="width: 327px;" color="#3861FB" :loading="submiting" @click="submit()">{{
					$t('button.otc.sure') }}</van-button>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue"
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t('form.signup.changeAssetsPsw'),
				navbarStyle: 'normal'
			},
			form: {
				oldPassword: "",
				sms: "",
				email: '',
				newPassword: "",
				rePassword: ""
			},
			submiting: false,
			newSet: false,
			updateSet: false,
			user: {},
			countingDown: false,
			countdownSeconds: 60
		}
	},
	components: { navbar },
	watch: {},
	computed: {
		buttonText() {
			return this.countingDown ? `${this.$t('button.assets.reSend')} ${this.countdownSeconds}s` : this.$t('button.assets.sendSms');
		},
	},
	mounted() {
		this.$mts.checkLogin('needback', () => {
			this.$mts.mustBondAll();
			this.hasEmail = this.$mts.getUserValue('email') ? true : false;
			this.hasMobile = this.$mts.getUserValue('mobile') ? true : false;
		});
	},

	created() {
		this.getMember().then((res) => {
			if (!res.fundsVerified) {
				this.newSet = true;
			} else {
				this.updateSet = true;
			}
		});
	},
	methods: {
		getMember() {
			//获取个人安全信息
			return this.$http.post(this.host + '/uc/approve/security/setting').then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					return new Promise((resolve, reject) => {
						this.user = resp.data;
						resolve(resp.data);
					});
				} else {
					this.$Message.error(this.loginmsg);
				}
			});
		},
		// 发送验证码
		sendSms(type) {
			this.$http.post(this.host + '/uc/email/update/password/code').then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					this.$toast(this.$t('message.emailSuc'));
					this.countingDown = true;
					const timer = setInterval(() => {
						this.countdownSeconds--;
						if (this.countdownSeconds === 0) {
							clearInterval(timer);
							this.countingDown = false;
							this.countdownSeconds = 60;
						}
					}, 1000);
				} else {
					this.countingDown = false;
					this.$toast(resp.message);
				}
			});
		},
		// 新用户没有设置资金密码
		handleSubmit() {
			let that = this;

			if (!that.$vld.checkValue(that.form.newPassword, 'password')
				|| !that.$vld.checkValue(that.form.rePassword, 'password')) {
				that.$toast(that.$t('validate.public.passwordError'));
				return;
			}
			if (that.form.newPassword != that.form.rePassword) {
				that.$toast(that.$t('validate.public.notSame'));
				return;
			}
			if (!that.submiting) {
				that.submiting = true;
				let param = {};
				param['jyPassword'] = this.form.newPassword;
				this.$http.post(this.host + '/uc/approve/transaction/password', param).then((response) => {
					var resp = response.body;
					if (resp.code == 0) {
						that.submiting = false;
						that.$toast(that.$t('validate.public.changeAssetsPswSuc'));
						this.getMember();
						that.$router.push({ name: 'my' });
					} else {
						that.$toast(resp.message);
					}
				});
			}
		},
		// 资金密码修改
		submit() {
			let that = this;
			if (!that.$vld.checkValue(that.form.oldPassword, 'password')) {
				that.$toast(that.$t('message.assets.t6'));
				return;
			}
			if (!that.$vld.checkValue(that.form.newPassword, 'password')
				|| !that.$vld.checkValue(that.form.rePassword, 'password')) {
				that.$toast(that.$t('validate.public.passwordError'));
				return;
			}
			if (that.form.newPassword != that.form.rePassword) {
				that.$toast(that.$t('validate.public.notSame'));
				return;
			}
			if (!that.$vld.checkValue(that.form.email, 'sms4')) {
				that.$toast(that.$t('validate.public.smsError'));
				return;
			}
			if (!that.submiting) {
				that.submiting = true;
				let param = {};
				param['oldPassword'] = that.form.oldPassword;
				param['newPassword'] = that.form.newPassword;
				param['msgCode'] = that.form.email;
				that.$http.post(that.host + '/uc/approve/update/transaction/password', param).then((response) => {
					var resp = response.body;
					if (resp.code == 0) {
						that.submiting = false;
						that.$toast(that.$t('validate.public.changeAssetsPswSuc'));
						this.getMember();
						that.$router.push({ name: 'my' });
					} else {
						that.$toast(resp.message);
					}
				});
			}
		}
	}
}
</script>
<style scoped>
.van-tabbar {
	height: 20.3562vw;
}
::v-deep .van-field__control {
	margin-bottom: 0;
}
.van-tabbar-item__text {
	width: 90vw;
}

.cellGroup {
	display: flex;
	align-items: center;
}

.cellGroup .tbTitle {
	text-align: right;
	min-width: 22.9008vw;
}

.cellGroup .van-field__control {
	margin-bottom: unset;
}

.btn-code {
	margin-right: 4.0712vw;
	box-sizing: border-box;
	border: 1px solid #3861FB;
	color: #3861FB;
	font-size: 3.5623vw;
}
</style>